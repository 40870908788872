<template>
  <div class="badge_box">
    <div class="badge_list">
      <client-only>
        <swiper-container ref="swiper" rewind="true" slides-per-view="auto" space-between="12" free-mode="true">
          <swiper-slide v-for="item in selectedInterest" :key="item.interestCd">
            <span>{{ item.interestName }}</span>
          </swiper-slide>
        </swiper-container>
      </client-only>
    </div>
    <div class="btn_setteing">
      <button type="button" @click="openInterestPopup">
        <i class="icon_size_24  icon_font_setting"></i>
        <span class="blind">설정</span>
      </button>
    </div>

    <ThePopupInterest ref="popupInterestRef" @update="handleUpdateInterest" />
  </div>
</template>

<script setup lang="ts">
import { useCodeStore } from "~/modules/globalCode/store/codeStore"
import { MST_INTEREST } from '~/modules/globalCode/types/code';

const props = defineProps<{ list: any[] }>()

const emit = defineEmits<{
  (e: 'update', selectedInterest: any[]): void,
}>();

const codeStore = useCodeStore()

const swiper = ref<any>(null)
const popupInterestRef = ref<any>(null)
const selectedInterest = ref<any[]>([])

onMounted(() => {
  selectedInterest.value = props.list

  initSwiper()
})

const initSwiper = () => {
  const swiperEl = document.querySelector('.badge_list swiper-container');
  if (swiperEl) {
    const swiperParams = {
      slidesPerView: 'auto',
      on: {
        init() {},
      },
    };
    Object.assign(swiperEl, swiperParams);
    (swiperEl as any).initialize();
  }
}

const updateSwiper = () => {
  nextTick(() => {
    swiper.value && swiper.value.swiper.update()
  })
}

const openInterestPopup = () => {
  const selectedInterestCodeList = selectedInterest.value.map((interest: any) => interest.interestCd)
  popupInterestRef.value && popupInterestRef.value.open(selectedInterestCodeList)
}

const handleUpdateInterest = (selectedInterestCode: string[]) => {
  const interestList = codeStore.list.find((code: any) => code.groupCd === MST_INTEREST)?.codeList
  const selectedInterestList = interestList
    .filter((code: any) => selectedInterestCode.some((interestCode: string) => interestCode === code.comCd))
    .map((code: any) => {
      return {
        interestCd: code.comCd,
        interestName: code.codeName
      }
    })
  selectedInterest.value = selectedInterestList
  updateSwiper()

  emit('update', selectedInterestList)
}

</script>

<style scoped>
.badge_box { position:relative; display: flex; justify-content: space-between; align-items: center; margin-bottom:24px; }
.badge_box .badge_list{width:calc(100% - 53px);}
.badge_list swiper-container {width: 100%;height: 100%;}
.badge_list swiper-slide {width:auto;}
.badge_list span {display:inline-block; font-size:var(--font-size-7);  color:var(--color-text-category); vertical-align:middle; height:1.875rem; line-height:1.875rem; padding:0 12px; border-radius:30px; background-color: rgba( 156, 221, 122, 0.16 );;}

.btn_setteing{width:37px; height:24px; border-left:1px solid var(--color-line); text-align:right;}

@media screen and (max-width: 768px) {
  .badge_box{margin-bottom:20px;padding-top:8px;}
}
</style>