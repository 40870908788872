import type { UseFetchOptions } from '#app'
import { post } from '@tms/core'
import type { IInterestListReponse } from '../types/response'
import { useCustomFetch } from '~/modules/core/fetch/custom-fetch'

/**
 * 내 관심사 목록
 * @param req
 * @returns
 */
export function useFetchMyInterestList (option?: UseFetchOptions<IInterestListReponse>) {
  return useCustomFetch('/my/v1/profile/interest', {
    ...option
  })
}

/**
 * 내 관심사 업데이트
 * @param req
 * @returns
 */
export function useUpdatehMyInterestList (params: { interestList : string[]}) {
  return post('/my/v1/profile/interest', params)
}
